<i18n>
{
    "es": {
        "PLACE_HOLDERS": {
            "INITIAL_DISCOUNT": "Descuento principal"
            , "FINAL_DISCOUNT":"Ultimo descuento"
        }
    }
}
</i18n>
<template>
<ModalForm
    id="modalFormEditFamilia"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- FOLIO -->
    <div class="row g-4 fv-row">
        <div class="col fv-row">
            <label class="required fs-6 fw-bold mb-2">Código</label>
            <Field  name="code" v-slot="{value, field, errorMessage}" style="width:100%">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        type="text" 
                        placeholder="Código"
                        :validate-event="false"
                        :disabled="isEditing"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </div>
        <div class="col fv-row">
            <label class="required fs-6 fw-bold mb-2">Código de Impuesto</label>
            <Field  name="taxCode" v-slot="{value, field, errorMessage}"  style="width:100%">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        type="text" 
                        placeholder="Código de Impuesto"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </div>
    </div>

    <!-- NOMBRE -->
    <div class="row g-4 fv-row rowItemControl">
        <label class="required fs-6 fw-bold mb-2">Nombre</label>
        <Field  name="name" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    type="text" 
                    placeholder="Nombre"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field> 
    </div>  
    <div class="row g-4 fv-row mb-8">
        <div class="col fv-row">
            <label class="fs-6 fw-bold mb-2">Descuento Minimo</label>
            <InputText 
                :disabled="readOnly"
                :name="'minimunDiscount'"
                :placeholder="$t('PLACE_HOLDERS.INITIAL_DISCOUNT')"
                :mask="maskNumber">
            </InputText>
        </div>
        <div class="col fv-row">
            <label class="fs-6 fw-bold mb-2">Descuento Maximo</label>
           <InputText 
                :disabled="readOnly"
                :name="'maximunDiscount'"
                :placeholder="$t('PLACE_HOLDERS.FINAL_DISCOUNT')"
                 :mask="maskNumber">
            </InputText>
        </div>
    </div>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
//import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import InputText from "@/components/forms/InputText.vue" 
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";

export default defineComponent({
    components:{
        Field,
        ModalForm,
        //SelectOption
        InputText
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nueva Familia"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let txtName = ref("");
        let controller = "Families";
        let taxes = ref([]);

        const validationSchema = Validations.object().shape({
            code: Validations.string().required().label("code"),
            name: Validations.string().required().label("name"),
            taxCode: Validations.string().required().label("taxCode"),
            initialDiscount: Validations.string(),
            finalDiscount: Validations.string()
        });

        let currentLineId = ref("");

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("Taxes", taxes);
        });

        //VARAIBLES COMPUTADAS
        const getCurrentLineId = computed(() => {
            return currentLineId.value;
        });

        const initialValues = computed(() => {
            let article = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                article.code = currentOpenedItem.value.code;
                article.name = currentOpenedItem.value.name;
                article.taxCode = currentOpenedItem.value.taxCode;
                article.initialDiscount = currentOpenedItem.value.initialDiscount;
                article.finalDiscount = currentOpenedItem.value.finalDiscount;
            }
            
            return article;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboTaxes = computed(() => taxes.value );

        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.setFieldValue("code", "");
            modalForm.value.$refs.form.setFieldValue("name", "");
            modalForm.value.$refs.form.setFieldValue("taxCode", "");
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;

            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        return {
            editForm,
            modalForm,
            txtName,
            validationSchema,

            //Variables computadas
            initialValues,
            readOnly,
            isEditing,
            getCurrentLineId,
            comboTaxes,

            //Funciones
            handleCrudAction,
            initDefaults
            , maskNumber: InputTextMaskTypes.Number
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }
</style>
